@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.navbar {
  position: absolute;
    width: 100%;
    height: auto;
    background-color: rgba(0, 0, 0, 0.137);
    z-index: 10;
}

.navbar .navbar-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.navbar-logo {
  width: 130px;
  z-index: 2;
}

.navbar-logo img {
  width: 100%;
  height: auto;
}

.navbar .icon {
  font-size: 1.4rem;
  color: #d1cdcd;
}

.navbar .logo .icon {
    font-size: 3rem;
    margin-right: 1rem;
    color: var(--primary-color);
}

.hamburger {
    display: none;
    padding: 1rem;
}

.navbar .nav-menu {
    height: 100%;
    align-items: center;
    display: flex;
    margin: auto 0;
}

.navbar li {
    width: max-content;
    font-family: 'Montserrat', sans-serif;
    padding: .2rem .7rem;
}

.navbar li a {
  color: #f0efef;
  font-size: 1rem;
  font-weight: 500;
  padding: 0 .3rem;
}

.navbar li a:hover {
  color: #8BC28E !important;
}

  .menu-item .sub__menus li a {
    color: #f5eeee;
    font-size: 13px;
  }

  .menu-item .sub__menus li a:hover {
    color: #083d0b !important;
  }


/* -----------------------------------------------------> SUB MENU */
.sub__menus {
    position: absolute;
    display: none;
    background-color: #49a3b3;
    border: none;
    width: auto;
    margin-top: 0;
    z-index: 1000;
    padding: .5rem 0;
    -webkit-box-shadow: 0px 0px 32px -20px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 32px -20px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 32px -20px rgba(0,0,0,0.75);

  }

  /* -----------------------------------------------------> DROPDOWN ARROW */
  .sub__menus__arrows {
    position: relative;
  }

  .sub__menus__arrows svg, .sub__menus__arrows__full svg {
    padding-top: 0px;
    position: absolute;
    top: 8px;
    color: #d1cdcd;
  }


  
@media screen and (max-width: 1200px) {
    .navbar .hamburger {
        display: block;
        z-index: 15;
    }

    .navbar .nav-menu {
        position: absolute;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        top: 0;
        left:-100%;
        justify-content: center;
        background: var(--primary-color);
        transition: 0.5s ease-in;
    }

    .navbar .active {
        left: 0;
    }

    .navbar li {
        font-size: 1rem;
    }

    .navbar button {
        font-size: 1.8rem;
    }

    .menu-item .sub__menus li a {
        font-size: 10px;
        text-align: left;
      }

      li .submenu-header {
        font-size: 14px;
      }

      .navbar .navbar-container {
        justify-content: space-between;
      }


}





