@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.residence-section-header {
    width: auto;
    padding: 20px;
    text-align: center;
    margin: 2rem auto;
}

.residence-section-header h1 {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
}

.residence-section-header p {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}


.residence-section-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.residence-section-flex-item {
  width: 350px;
}

.residence-section-flex-item img {
  width: 100%;
}




