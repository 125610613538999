@import url('https://fonts.googleapis.com/css2?family=Bacasime+Antique&display=swap');

.section2 {
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  .section2:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.03;

  }

.section2 {
    width: 100%;
    height: auto;
    position: relative;
}

.section2-content {
    text-align: center;
    padding: 3rem 0 0  0;
}

.section2-content h1 {
    font-size: 3rem;
    padding: 0 1rem;
    color: var(--primary-text);
    font-family: 'Bacasime Antique', serif;

}

.section2-content h2 {
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 1px;
}


.section2-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    padding: 3rem 0;
}

.section2-flex-left {
    width: 40%;
    padding: 1rem;
    margin: auto;
}

.section2-flex-left h1 {
    font-size: 1.2rem;
    padding: 1rem 0;
}

.section2-flex-left p {
    text-align: justify;
    font-size: 14px;
}

.section2-flex-right {
    width: 550px;
    padding: 1rem;
    margin: auto;
}

.section2-flex-right img {
    width: 100%;
    border-radius: 10px;
}


.section2-btn button {
    background-color: #49a3b3;
    cursor: pointer;
    color: white;
    border-radius: 5px;
    border: none;
    padding: .7rem 2rem;
    text-transform: uppercase;
    font-size: 1rem;
}


.section2-btn button:hover {
  background-color: #61ce70;
}

/* @media screen and (max-width:1200px) {
    .section2-flex-left {
        width: 100%;
    }
} */

@media screen and (max-width:960px) {
    .section2-content h1 {
            font-size: 2rem;
        }

        .section2-flex-left {
            width: 100%;
        }

        .section2-flex-right {
            width: 80%;
        }
        
        .section2-flex-left h1 {
            text-align: center;
        }
        
        .section2-flex-left p {
            text-align: center;
        }

        .section2-flex-left .section2-btn {
            text-align: center;
        }
        
    }

    @media screen and (max-width:700px) {
        .section2-flex-right {
            width: 100%;
        }
        
    }