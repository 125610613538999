@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bacasime+Antique&display=swap');

.wwa-bg {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 3rem 0;
  }
  
  .wwa-bg:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.03;
  }

.wwa {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem 0;
    gap: 2rem;
}

.wwa .left {
    width: 500px;
    padding: 1rem;
}

.wwa .left img {
    width: 100%;
    border-radius: .5rem;
}

.wwa .right {
    width: 600px;
    padding: 1rem;

}

.wwa .right p {
    font-size: 16px;
    text-align: justify;
}


.wwa .right h2 {
    font-size: 3.5rem;
    color: var(--primary-text);
    font-family: 'Bacasime Antique', serif;
    line-height: 3rem;
}

.wwa .right h3 {
    font-size: 1rem;
    letter-spacing: 2px;
    padding: 1rem 0;
    line-height: 2rem;
    color: var(--primary-color);
}


.wwa .right span {
    color: var(--secondary-color);
}




@media screen and (max-width:940px) {

    .wwa {
        flex-wrap: wrap-reverse;
    }

    .wwa .right {
        width: 100vw;
        padding: 2rem;
    }
    .wwa .left {
        width: 90vw;
        padding: 2rem;
    }

    .wwa .right h2 {
        font-size: 2rem;
        text-align: center;
    }

    .wwa .right h3 {
        text-align: center;
    }


    .wwa .right p {
        font-size: 14px;
        text-align: center;
    }
    
}



@media screen and (max-width:700px) {
    .wwa .right h2 {
        line-height: 2rem;
    }
}
