@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.dd {
    width: 100%;
    height: 60vh;
    background-color: rgba(0, 0, 0, 0.479);
    position: relative;
  }
  
  .dd:before {
    content: '';
    position: absolute;
    background: url('../../assets/ddt-bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  

.dd {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.dd .dd-content {
    width: 50%;
    display: block;
    position: absolute;
    bottom: 20%;
    right: 5%;
    padding: 1rem;
}

.dd h1 {
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    color:white;
}

.dd h3 {
    color:white;
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
}

/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.dd .dd-content {
    width: 100%;
    display: block;
    position: absolute;
    bottom: 10%;
    right: 0;
    padding: 1rem;
    }

.dd h1 {
    font-size: 2rem;
    text-align: center;
}

.dd h3 {
    font-size: 1rem;
    text-align: center;
}

}