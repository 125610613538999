@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.stimulant {
    width: 100%;
    height: 70vh;
    background-color: rgba(0, 0, 0, 0.479);
    position: relative;
  }
  
  .stimulant:before {
    content: '';
    position: absolute;
    background: url('../../assets/addiction-submenu-bg.webp') no-repeat center top/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  

.stimulant {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.stimulant .stimulant-content {
    display: block;
    position: absolute;
    bottom: 20%;    
    padding: 1rem;
}

.stimulant h1 {
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
    color: rgb(245, 247, 248);
}

.stimulant h3 {
    color: white;
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
}

/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.stimulant h1 {
    font-size: 2rem;
}

.stimulant h3 {
    font-size: 1rem;
}
}