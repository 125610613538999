@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.job-section {
    width: 100%;
    position: relative;
    background: rgba(255, 255, 255, 0);
    padding: 3rem 0;
}

.job-section-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding: 1rem;
    gap: 1rem;
}

.job-section-left {
    width: 50%;
    padding: 1rem;
    margin: auto;
}

.job-section-right {
    width: 500px;
    padding: 1rem;
}

.job-section-right img {
    width: 100%;
}

.job-section-content p {
    font-family: 'Montserrat', sans-serif;
    text-align: justify;
    color: rgb(0, 0, 0);
    margin: 1rem;
    font-size: 14px;
    line-height: 20px;
}

.job-section h1 {
    font-size: 2rem;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    color: var(--primary-color);
}



@media screen and (max-width: 940px) {
    .job-section-left {
        width: 100%;
    }

    .job-section h1 {
        font-size: 25px;
    }

    .job-section-left p {
        font-size: 15px;
    }
}






