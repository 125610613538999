@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.method {
  width: 100%;
  height: 60vh;
  position: relative;
  background: rgba(23, 37, 58, 0.541);
}

.method:before {
  content: '';
  position: absolute;
  background: url('../../assets/detox-bg.webp') no-repeat center center/cover;
  height: 100%;
  width: 100%;
  z-index: -1;
}
  
  .method-content {
    bottom: 20%;
    left: 5%;
    position: absolute;
}


.method-content h1{
  text-align: center;
  color: white;
  font-size: 3rem;
}

/* MEDIA QUERIES - HERO */

@media screen and (max-width:1200px) {

.method h1 {
    font-size: 2rem;
    text-align: center;
}

.method-content {
  width: 100%;
  left: 0%;
  position: absolute;
}




}