@import url('https://fonts.googleapis.com/css2?family=Bacasime+Antique&display=swap');

.section1 {
    padding: 5rem 0;
}

.section1 h1 {
    font-size: 2rem;
    font-family: 'Bacasime Antique', serif;
    color: var(--primary-text);
    line-height: 3rem;
    padding: .5rem 0;
}

.section1-header {
    text-align: center;
    padding: 1rem;
}

.section1-header h2 {
    font-family: 'Outfit', sans-serif;    
    text-transform: uppercase;
    font-size: 1.5rem;
}

.section1-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}

.section1-card {
    width: 350px;
}

.section1-card img {
    width: 100%;
}

.card-body {
    text-align: center;
}


@media screen and (max-width:800px) {
    .section1-card {
        width: 80%;
    }

    .section1 h1 {
        font-size: 2rem;
        line-height: 2rem;
    }
    
}