.contact-form-content {
  padding: 3rem 0;
}


.contact-container {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .contact-container:before {
    content: '';
    position: absolute;
    height: 50%;
    width: 100%;
    z-index: -1;
    top: 25%;
  }

  .contact-left {
    width: 600px;
    margin: auto;
    padding: 1rem;
  }

  .contact-left img {
    width: 100%;
  }
  
  .get_form_inner {
    padding: 2rem 2rem ;
    background: #fff;
    box-shadow: -4px -2px 20px -7px #cfd5df;
    margin: auto;
  }

input {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

  input[type="text"], input[type="text"], input[type="email"], input[type="tel"] {
    border: 1px solid #dbdbdb;
    border-radius: 2px;
    color: #333;
    height: 42px;
    padding: 0 0 0 20px;
    width: 100%;
    outline: 0;
  }

  textarea {
    border: 1px solid #dbdbdb;
    border-radius: 2px;
    color: #333;
    padding: 12px 0 0 20px;
    width: 100%;
    outline: 0;
    margin-bottom: 20px;
    resize: none;
  }

  .get_form_inner_text h3 {
    color: var(--primary-color);
    font-size: 26px;
    font-weight: 600;
  }

  .get_form_inner_text p {
    text-align: left;
  }

  input[type="submit"] {
    background-color: var(--primary-color);
    border: var(--primary-color);
    color: #fff;    
    display: inline-block;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    padding: 10px 20px;
    outline: 0;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -webkit-transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -moz-transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -ms-transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -o-transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  input[type="submit"]:hover {
    background-color: rgb(241, 134, 33);
    border-color: var(--secondary-color);
    color: #fff;
  }


  /* CARDS */

  .contact-cards-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

  }

  .get_form_inner2 {
    display: block;
    width: 300px;
    height: 150px;
    padding: 2rem 1rem;
    background: #fff;
    box-shadow: -4px -2px 20px -7px #cfd5df;
    margin: 4rem 1rem 1rem 1rem;
  }

  .get_form_inner2  h2 {
    color: var(--primary-color);
    text-align: center;
    }
    
    .get_form_inner2 p, .get_form_inner2 a {
    color: rgb(39, 38, 38);
    text-align: center;
    margin: 1rem auto;
    }

    .disclaimer p {
      padding: 1rem 0;
      font-size: 12px;
    }
    
    .disclaimer span {
      padding: 1rem 0;
      font-size: 12px;
      color: var(--primary-color);
    }
  

    @media screen and (max-width: 600px) {
      .get_form_inner2 {
        display: block;
        width: 100%;
        height: auto;
      }
    }