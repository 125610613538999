@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');



.benzodiazepines-detox-section {
  width: 100%;
  height: auto;
  background: rgba(0, 0, 0, 0);
  position: relative;
}

.benzodiazepines-detox-section:before {
  content: '';
  position: absolute;
  background: url('../../assets/section-bg.webp') no-repeat center center/cover;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0.03;
}

.benzodiazepines-detox-section-header {
  text-align: justify;
  padding: 1rem;
}

.benzodiazepines-detox-section-header p {
  text-align: justify;
}

.benzodiazepines-detox-section-header h1 {
  padding: 1rem 0;
  font-size: 1rem;
}

.benzodiazepines-detox-section-left h5 {
  padding: 1rem 0;
  color: rgb(201, 140, 8);
}

.benzodiazepines-detox-section-header h5 {
  padding: 1rem 0;
  color: rgb(201, 140, 8);
}

.benzodiazepines-detox-section-content {
  padding: 3rem 1rem;
}

.benzodiazepines-detox-section-flex {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.benzodiazepines-detox-section-left {
  width: 50%;
  margin: auto;
}

.benzodiazepines-detox-section-left h1 {
  padding: 1rem 0;
  font-size: 1rem;
}

.benzodiazepines-detox-section-right {
  width: 500px;
  margin: auto;
  -webkit-box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
  -moz-box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
  box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
}

.benzodiazepines-detox-section-right img {
  width: 100%;
  border-radius: 15px;
  padding: .5rem;
}



/* MEDIA QUERIES */

@media screen and (max-width:940px) {

  .benzodiazepines-detox-section-flex {
    flex-wrap: wrap;
  }
  
  .benzodiazepines-detox-section-left {
    width: 100%;
    margin: auto;
  }
  
  .benzodiazepines-detox-section-right {
    width: 100%;
    margin: auto;
  }

  .benzodiazepines-detox-section-left h1 {
    text-align: center;
  }
  
  }



