@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap');



.hero {
  width: 100%;
  height: 85vh;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: #0901188f;
}

.hero:before {
  content: '';
  position: absolute;
  background: url('../../assets/hero-bg.webp') no-repeat center center/cover;
  height: 100%;
  width: 100%;
  z-index: -1;
}

/* video {
    position: relative;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
   */

.hero-container .content {
  position: absolute;
  width: 70%;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
}

.hero h1 {
    font-size: 1.2rem;
    color: #d1cdcd;
    font-family: 'Outfit', sans-serif;    
    letter-spacing: 2px;
    text-align: center;
    font-weight: 300;
    margin: 0;
}

.hero h2 {
  font-size: 5rem;
  color: #d1cdcd;
  font-family: 'EB Garamond', serif;  
  letter-spacing: 1px;
  text-align: center;
  margin: 0;
  animation: growText 10s ease-in-out infinite;
}

/* Grow text animation */
@keyframes growText {
  0% {
      transform: scale(1);
  }
  50% {
      transform: scale(1.2);
  }
  100% {
      transform: scale(1);
  }
}


.hero h3 {
  font-size: 1.5rem;
  color: #d1cdcd;
  font-family: 'Outfit', sans-serif;    
  letter-spacing: 2px;
  text-align: center;
  font-weight: 300;
  margin: 0;
  text-transform: uppercase;
}

.hero p {
  font-size: 18px;
  color: white;
  letter-spacing: 2px;
  text-align: center;
  padding: .5rem 0;
}

.hero .hero-btn {
  text-align: center;
}

.hero .hero-btn button {
    background-color: rgba(255, 255, 255, 0.473);
    cursor: pointer;
    color: white;
    border-radius: 5px;
    border: none;
    padding: .7rem 2rem;
    text-transform: uppercase;
    font-size: 1rem;
}

.hero .hero-btn button:hover {
  background-color: #3b6e42;
}





/* MEDIA QUERIES */



@media screen and (max-width:940px) {

    .hero h1 {
      text-align: center;
    }

    .hero h2 {
        font-size: 3rem;
    }

    .hero-container .content {
      width: 100%;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
    }


}

@media screen and (max-width:600px) {

  .hero h1 {
    font-size: 1rem;
  }

  .hero h2 {
    line-height: 4rem;
    font-size: 2rem;
  }

  .hero h3 {
    font-size: 1rem;
  }

  .hero p {
    font-size: 15px;
  }
  

  .hero {
    height: 100vh;
  }
    

}


