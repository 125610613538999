@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.detox-alcohol {
    width: 100%;
    height: 70vh;
    background-color: rgba(0, 0, 0, 0.479);
    position: relative;
  }
  
  .detox-alcohol:before {
    content: '';
    position: absolute;
    background: url('../../assets/detox-alcohol-bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  

.detox-alcohol {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.detox-alcohol .detox-alcohol-content {
    display: block;
    position: absolute;
    bottom: 20%;
    left: 10%;
    padding: 1rem;
}

.detox-alcohol h1 {
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
    color: rgb(245, 239, 239);
    text-transform: uppercase;
    text-align: center;
}

.detox-alcohol h2 {
    font-size: 1.2rem;
    font-family: 'Montserrat', sans-serif;
    color: rgb(245, 239, 239);
    text-align: center;
}


/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.detox-alcohol h1 {
    font-size: 2rem;
    text-align: center;
}

.detox-alcohol h2 {
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
    color: rgb(245, 239, 239);
    text-align: center;
}

.detox-alcohol .detox-alcohol-content {
    left: 0%;
    width: 100%;
}


}