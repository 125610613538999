@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bacasime+Antique&display=swap');

.section3 {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem 0;
    gap: 2rem;
    background-color: #f8f9fa;
}

.section3 .section3-left {
    width: 500px;
    padding: 1rem;
}

.section3 .section3-left img {
    width: 100%;
    border-radius: .5rem;
}

.section3 .section3-right {
    width: 600px;
    padding: 1rem;

}

.section3 .section3-right p {
    font-size: 14px;
    text-align: justify;
}


.section3 .section3-right h2 {
    font-size: 3.5rem;
    color: var(--primary-text);
    font-family: 'Bacasime Antique', serif;
    line-height: 3rem;
}

.section3 .section3-right h3 {
    font-size: 1rem;
    letter-spacing: 2px;
    padding: 1rem 0;
    line-height: 2rem;
    color: var(--primary-color);
}

.section3 .section3-right span {
    color: var(--secondary-color);
}




@media screen and (max-width:940px) {

    .section3 {
        flex-wrap: wrap-reverse;
    }

    .section3 .section3-right {
        width: 100vw;
        padding: 2rem;
    }
    .section3 .section3-left {
        width: 90vw;
    }

    .section3 .section3-right h2 {
        font-size: 2rem;
        text-align: center;
    }

    .section3 .section3-right h3 {
        text-align: center;
    }


    .section3 .section3-right p {
        font-size: 14px;
        text-align: center;
    }
    
}



@media screen and (max-width:700px) {
    .section3 .section3-right h2 {
        line-height: 2rem;
    }
}
