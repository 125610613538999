@import url('https://fonts.googleapis.com/css2?family=Bacasime+Antique&display=swap');

.sa-thumbnail-header {
  padding: 3rem 1rem;
  text-align: center;
}

.sa-thumbnail-header h1 {
  font-size: 2rem;
  font-family: 'Bacasime Antique', serif;
}

.sa-thumbnails-contents {
  margin-bottom: 3rem;
}

.sa-thumbnails-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: .5rem;
}

.sa-thumbnail {
  width: 350px;
  height: auto;
  -webkit-box-shadow: 0px 0px 17px -8px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 17px -8px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 17px -8px rgba(0,0,0,0.75);
}

.sa-thumbnail h1 {
  text-align: center;
  font-size: 1rem;
  text-transform: uppercase;
  padding: 1rem;
}

.sa-thumbnail img {
  width: 100%;
}

.thumbnail-btn {
  text-align: center;
}

.thumbnail-btn button {
  font-size: 14px;
  text-transform: uppercase;
  padding: .5rem 1rem;
  border-radius: 5px;
}






@media (max-width: 940px) {
  .sa-thumbnail-header h1 {
    font-size: 2rem;
    font-family: 'Bacasime Antique', serif;
  }

}

